<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="$t('agreement.PrivacyPolicyqf', {project, projectUpperCase, email})"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  name: 'PrivacyPolicyqf',
  metaInfo: {
    title: 'Privacy Policy --Bifrost', // set a title
    link: [
      // { rel: 'asstes', href: 'https://assets-cdn.github.com/' }
    ]
  },
  data () {
    return {
      project: 'Bifrost',
      projectUpperCase: 'BIFROST',
      email: 'contact@bifrost.com'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || 'BIFROST'
    this.project = formatFirstUpperCase(flavor).toLowerCase()
    this.projectUpperCase = flavor.toUpperCase()
    this.email = `contact@${flavor.toLowerCase()}.com`
  }
}
</script>

<style lang="less">
  @import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
  .wrapper{
    padding: 0 5%;
    background:#F0F0F0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
</style>
